import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './translations/en.json';
import es from './translations/es.json';
import fr from './translations/fr.json';

export const ENGLISH = 'en';
export const SPANISH = 'es';
export const FRENCH = 'fr';

export const DEFAULT_LANGUAGE = ENGLISH;
export const SUPPORTED_LANGUAGES = [ENGLISH, SPANISH, FRENCH];
export const LANGUAGE_NAMES = {
  [ENGLISH]: 'English',
  [SPANISH]: 'Español',
  [FRENCH]: 'Français',
};

const resources = {
  en,
  es,
  fr,
};

i18n.use(initReactI18next).init({
  resources,
  lng: DEFAULT_LANGUAGE,
  fallbackLng: DEFAULT_LANGUAGE,
  debug: process.env.NODE_ENV !== 'production',

  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
