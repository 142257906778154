import React from 'react';
import propTypes from 'prop-types';
import { Layout } from '@bitsoflove/pattyn-360/lib/components';
import { useRouter } from 'next/router';

import { Sticky } from 'react-sticky';
import MachineShortInfoContainer from '~/containers/MachineShortInfoContainer';

function DefaultMachineLayout({ children }) {
  const {
    query: { machineInstanceId },
  } = useRouter();

  return (
    <Layout.Grid addNegativeSpace>
      <Layout.Column>{children}</Layout.Column>
      <Layout.Column width="25%">
        <Sticky relative>
          {({ style }) => (
            <div style={style}>
              <MachineShortInfoContainer
                machineInstanceId={machineInstanceId}
              />
            </div>
          )}
        </Sticky>
      </Layout.Column>
    </Layout.Grid>
  );
}

DefaultMachineLayout.propTypes = {
  children: propTypes.node.isRequired,
};

export default DefaultMachineLayout;
