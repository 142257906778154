import gql from 'graphql-tag';

export default gql`
  query GET_SITES {
    app {
      id
      clients {
        id
        name
        sites {
          id
          name
          lines {
            id
            name
            machineInstances {
              id
              serialNumber
              machine {
                id
                name
                type {
                  slug
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_SITE = gql`
  query GET_SITE($siteId: ID!) {
    app {
      id
      site(id: $siteId) {
        id
        name
        addressLine1
        addressLine2
        country
        contacts {
          id
          title
          lastName
          firstName
          phoneNumber
          email
        }
        thumbnail {
          id
          src
          title
        }
        reports {
          id
          title
          asset {
            id
            src
          }
          createdAt
        }
      }
    }
  }
`;
